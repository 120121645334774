import { API } from "./api-service";

export class dataCommonFunction {
  static handleActionBox(item, rows, action, indexes, token) {
    switch (action) {
      case 1:
        indexes &&
          indexes.map((index) => {
            API.updateItem(item, rows[index].id, { active: false }, token)
              .then((resp) => {
                if (resp.active) {
                  rows[index]["active"] = "Yes";
                } else {
                  rows[index]["active"] = "No";
                }
                return rows;
              })
              .catch((error) => console.log(error));
          });
        return rows;

        break;
      case 2:
        indexes &&
          indexes.map((index) => {
            API.updateItem(item, rows[index].id, { active: true }, token)
              .then((resp) => {
                if (resp.active) {
                  rows[index]["active"] = "Yes";
                } else {
                  rows[index]["active"] = "No";
                }
                return rows;
              })
              .catch((error) => console.log(error));
          });
        return rows;
        break;
      default:
        return rows;
        break;
    }
  }

  static createSelectOption(options_db) {
    const options = [];
    options_db &&
      options_db.map((t) => {
        options.push({ value: t.id, label: t.name });
      });

    return options;
  }

  static createOption(item, body, token, list) {
    setTimeout(() => {
      return API.createItem(item, body, token)
        .then((resp) => {
          let option = { value: resp.result.id, label: resp.result.name };
          list = list.push(option);
          return list;
        })
        .catch((error) => console.log(error));
    }, 1000);
  }

  static async getThumpUrl(id) {
    var link = "articlefiles/" + id.toString() + "/";

    let files = await API.getItemsFront(link)
      .then((resp) => {
        return resp.files;
      })
      .catch((error) => console.log(error));

    var url = "";
    if (files.length > 0) {
      url = "url(" + files[0].file + ")";
    } else {
      url =
        "url(https://storage.googleapis.com/tinicorner_test/thumpUrlDefault1.jpeg)";
    }
    return url;
  }
}
