import React, { useState, useEffect } from "react";
import { API } from "../common/api-service";
import { dataCommonFunction } from "../common/data-common-function";
import Sidebar from "../layout/Sidebar";
import ArticlesList from "./ArticlesList";
import Slider from "../layout/Slider";

export default function TagArticles(props) {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    var slug = props.match.params.slug;
    var link = `get_articles_by_tag_slug/${slug}/${process.env.REACT_APP_DOMAIN}/`;
    API.getItemsFront(link)
      .then(async (resp) => {
        var datas = resp.articles;
        var articlesList = [];
        for (let i = 0; i < datas.length; i++) {
          const url = await dataCommonFunction
            .getThumpUrl(datas[i].id)
            .catch(console.error);
          var cat_list = await API.getItemsFront(
            `get_cat_list_by_article_id/${datas[i].id}/${process.env.REACT_APP_DOMAIN}/`
          )
            .then((resp1) => {
              return resp1.cat_list;
            })
            .catch((error) => console.log(error));
          var article = {
            id: datas[i].id,
            title: datas[i].title,
            slug: datas[i].slug,
            body: datas[i].body,
            created_at: datas[i].created_at,
            is_finished: datas[i].is_finished,
            thumpUrl: url,
            cat_list: cat_list,
          };
          articlesList.push(article);
        }
        setArticles(articlesList);
      })
      .catch((error) => console.log(error));
  }, [props.match.params.slug]);

  return (
    <React.Fragment>
      <Slider />
      <div id="colorlib-container">
        <div className="container">
          <div className="row">
            <div className="content">
              <ArticlesList articles={articles} />
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
