import React, { useState, useEffect } from "react";
import SingleCategory from "./SingleCategory";

export default function ChildrenCategory(props) {
  const [ChildCategoryNumber, setChildCategoryNumber] = useState(0);
  useEffect(() => {
    let catNum = 0;
    props.categories.map((cat) => {
      if (cat.parent === props.parentId) {
        catNum++;
      }
    });
    setChildCategoryNumber(catNum);
  }, [props.categories, props.parentId]);

  let renderChildrenCategory = (parentId) =>
    props.categories.map((cat, index) => (
      <React.Fragment>
        {cat.parent === parentId && (
          //   <div style={{ width: "80%", marginLeft: "40px" }} key={comment.id}>
          //   <div style={{ margin: "30px 0 0 130px" }} key={comment.id}>
          <div style={props.style} key={cat.id}>
            <SingleCategory index={cat.id} cat={cat} />
            <ChildrenCategory categories={props.categories} parentId={cat.id} />
          </div>
        )}
      </React.Fragment>
    ));

  return (
    <React.Fragment>{renderChildrenCategory(props.parentId)}</React.Fragment>
  );
}
