import React, { useState, useEffect } from "react";
import SingleComment from "./SingleComment";

export default function ReplyComment(props) {
  const [ChildCommentNumber, setChildCommentNumber] = useState(0);
  const [OpenReplyComments, setOpenReplyComments] = useState(false);
  useEffect(() => {
    let commentNumber = 0;
    props.CommentLists.map((comment) => {
      if (comment.parent === props.parentCommentId) {
        commentNumber++;
      }
    });
    setChildCommentNumber(commentNumber);
  }, [props.CommentLists, props.parentCommentId]);

  let renderReplyComment = (parentCommentId) =>
    props.CommentLists.map((comment, index) => (
      <React.Fragment>
        {comment.parent === parentCommentId && (
          //   <div style={{ width: "80%", marginLeft: "40px" }} key={comment.id}>
        //   <div style={{ margin: "30px 0 0 130px" }} key={comment.id}>
          <div style={{ margin: "30px 0 0 50px" }} key={comment.id}>
            <SingleComment
              comment={comment}
              parentId={comment.id}
              handleReplySubmit={props.handleReplySubmit}
            />
            <ReplyComment
              CommentLists={props.CommentLists}
              parentCommentId={comment.id}
              articleId={props.articleId}
              handleReplySubmit={props.handleReplySubmit}
            />
          </div>
        )}
      </React.Fragment>
    ));

  const handleChange = () => {
    setOpenReplyComments(!OpenReplyComments);
  };

  return (
    <div>
      {ChildCommentNumber > 0 && (
        <p
          style={{ fontSize: "14px", margin: "20px 0 30px 50px", color: "gray" }}
          onClick={handleChange}
        >
          {OpenReplyComments ? "Hide" : "View"} {ChildCommentNumber} more {ChildCommentNumber > 1 ? "comments" : "comment"}
        </p>
      )}

      {OpenReplyComments && renderReplyComment(props.parentCommentId)}
    </div>
  );
}
