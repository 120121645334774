import React, { useState, useEffect } from "react";

export default function Slider(props) {
  // const [articles, setArticles] = useState([]);

  // useEffect(() => {
  //   console.log("props.articles", props.articles);
  //   setArticles(props.articles);
  // }, [props.articles]);

  return (
    <aside
      id="colorlib-breadcrumbs"
      style={{
        backgroundImage:
          "url(https://storage.googleapis.com/tinicorner_test/saubeobanner1.jpg)",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 breadcrumbs text-center">
            <h2>Gác truyện của Sấu Béo</h2>
          </div>
        </div>
      </div>
    </aside>
    // <aside id="colorlib-hero">
    //   <div className="flexslider">
    //     <ul className="slides">
    //       <li key="1"
    //         style={{
    //           backgroundImage: "url(images/img_bg_1.jpg)",
    //         }}
    //       >
    //         <div class="overlay"></div>
    //         <div class="container-fluid">
    //           <div class="row">
    //             <div class="col-md-6 col-sm-12 col-md-offset-3 slider-text">
    //               <div class="slider-text-inner">
    //                 <div class="desc">
    //                   <h1>
    //                     <span class="text-center">
    //                       <small>1</small>
    //                     </span>{" "}
    //                     Top 5 Places To Start Your Vacation This Summer
    //                   </h1>
    //                   <h2>
    //                     <span>January 20, 2017</span>
    //                   </h2>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </li>
    //       {/* {props.articles &&
    //         props.articles.map((data, index) => (
    //           <li
    //             key={index}
    //             style={{
    //               backgroundImage: "url(images/img_bg_1.jpg)",
    //             }}
    //           >
    //             <div className="overlay"></div>
    //             <div className="container-fluid">
    //               <div className="row">
    //                 <div className="col-md-6 col-sm-12 col-md-offset-3 slider-text">
    //                   <div className="slider-text-inner">
    //                     <div className="desc">
    //                       <h1>
    //                         <span className="text-center">
    //                           <small>1</small>
    //                         </span>{" "}
    //                         {data.title}
    //                       </h1>
    //                       <h2>
    //                         <span>
    //                           {new Intl.DateTimeFormat("en-US", {
    //                             month: "long",
    //                             day: "2-digit",
    //                             year: "numeric",
    //                           }).format(new Date(data.created_at))}
    //                         </span>
    //                       </h2>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </li>
    //         ))} */}
    //     </ul>
    //   </div>
    // </aside>
  );
}
