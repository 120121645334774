import React from "react";

export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <aside id="colorlib-breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12 breadcrumbs text-center">
              <h2>Chính sách về quyền riêng tư</h2>
            </div>
          </div>
        </div>
      </aside>
      <div id="colorlib-container">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <h4>CHÍNH SÁCH VỀ QUYỀN RIÊNG TƯ TRÊN INTERNET</h4>
              <p>
                Tại Gác Sấu Béo, chúng tôi thực hiện hiện việc “bảo đảm” quyền
                riêng tư của bạn trên trang web này. Xin đọc Chính Sách Về Quyền
                Riêng Tư Trên Internet dưới đây. Chính sách này giải thích rõ
                cách thức mà Gác Sấu Béo chúng tôi nỗ lực để xây dựng lòng tin
                của bạn và bảo đảm an toàn cho các thông tin của bạn thông qua
                các chính sách trên trang web của chúng tôi.
              </p>
              <p>
                Chính Sách Về Quyền Riêng Tư Trên Internet áp dụng cho việc quy
                cập của bạn vào trang web của chúng tôi tại gacsaubeo.com và các
                trang web con.
              </p>
              <br />
              <h4>THU THẬP THÔNG TIN</h4>
              <p>
                Các thông tin được Gác Sấu Béo thu thập phụ thuộc vào yêu cầu
                của bạn. Việc thu thập các thông tin này là nhằm giúp chúng tôi
                cung cấp tốt hơn các sản phẩm mà chúng tôi cung cấp cho bạn.
                Dưới đây là vài ví dụ về các loại thông tin mà chúng tôi thu
                thập trên trang web của Gác Sấu Béo nếu bạn đồng ý cung cấp
                chúng:
              </p>
              <ul>
                <li>
                  “Liên lạc với chúng tôi” & các mẫu yêu cầu thư điện tử – Tên &
                  địa chỉ thư điện tử
                </li>
                <li>
                  Mẫu yêu cầu cung cấp thông tin – Tên & địa chỉ thư tín và/hoặc
                  địa chỉ thư điện tử.
                </li>
              </ul>
              <br />
              <h4>SỬ DỤNG CÁC THÔNG TIN THU THẬP</h4>
              <p>
                Các thông tin mà Gác Sấu Béo thu thập sẽ được sử dụng để cung
                cấp báo giá khi được yêu cầu, quản lý các hợp đồng bảo hiểm, trả
                lời các câu hỏi hoặc thắc mắc, đăng ký tham gia vào các sự kiện
                do Gác Sấu Béo tài trợ, và cung cấp các thông tin được yêu cầu
                một cách kịp thời.
              </p>
              <p>
                Bằng việc sử dụng trang web của chúng tôi, Gác Sấu Béo có thể
                lấy được các thông tin chung mang tính thống kê (từ các tập tin
                nhật ký (log files)) ví dụ như: tên miền, địa chỉ IP hoặc loại
                trình duyệt của những người sử dụng trang web của chúng tôi. Các
                thông tin lấy được từ tập tin nhật ký (log file) không chứa đựng
                các thông tin giúp xác định danh tính cá nhân, như tên, địa chỉ,
                điện thoại, v.v… và chỉ được sử dụng cho mục đích thống kê
                chung.
              </p>
              <br />
              <h4>COOKIES</h4>
              <p>
                Cookie là gì? Cookies là các tập hợp thông tin mà máy chủ của
                trang web của chúng tôi cấp cho bạn khi bạn sử dụng trang web
                của chúng tôi. Cookies được sử dụng trong một vài khu vực trên
                trang web của chúng tôi chỉ để duy trì các thông tin giữa các
                trang. Chúng sẽ không được sử dụng để lưu giữ hoặc xác định các
                thông tin cá nhân của các khách hàng của chúng tôi. Các Cookies
                này đơn giản chỉ nhằm đưa các thông tin bạn cung cấp trên một
                trang màn hình sang trang màn hình tiếp theo, giúp bỏ bớt yêu
                cầu phải cung cấp lại thông tin. Các Cookies này không thu thập
                bất cứ thông tin cá nhân nào của bạn hoặc của máy tính của bạn.
                Bạn có quyền chọn chấp nhận hoặc không chấp nhận Cookie, tuy
                nhiên, nếu bạn từ chối Cookie hoặc nếu chức năng Cookies trên
                trình duyệt của bạn bị tắt, một vài phần của trang web này sẽ
                không hoạt động được.
              </p>
              <br />
              <h4>CÁC TRANG WEB KHÔNG THUỘC GÁC SẤU BÉO</h4>
              <p>
                Gác Sấu Béo không chịu trách nhiệm về các chính sách về quyền
                riêng tư cũng như nội dung của bất cứ trang web nào trên mạng
                Internet có thể có đường dẫn đến trang web của Gác Sấu Béo. Xin
                được lưu ý rằng khi bạn bấm chuột vào các trang web ngoài trang
                web gacsaubeo.com, bạn đã được chuyển sang một trang web khác có
                thể không thuộc quyền quản lý của Gác Sấu Béo. Chúng tôi khuyến
                nghị bạn nên đọc các chính sách về quyền riêng tư của các trang
                web được dẫn đến đó vì các chính sách về quyền riêng tư của họ
                có thể khác với chính sách của chúng tôi.
              </p>
              <br />
              <h4>CHIA SẺ CÁC THÔNG TIN ĐƯỢC THU THẬP</h4>
              <p>
                Trang web Gác Sấu Béo được thiết kế để cung cấp cho các khách
                hàng, các khách hàng tiềm năng của chúng tôi và những người
                viếng thăm trang web của chúng tôi một phương thức bảo mật để có
                được các thông tin về Công ty chúng tôi, các dịch vụ và sản phẩm
                của chúng tôi, và để giao dịch với chúng tôi qua Internet. Trang
                web của chúng tôi sẽ chỉ thu thập các thông tin mà bạn tự nguyện
                cung cấp để giúp chúng tôi đáp ứng các yêu cầu của bạn. Nếu bạn
                đã cung cấp các thông tin cá nhân trên trang web của chúng tôi
                để yêu cầu cung cấp báo giá, để chúng tôi quản lý các hợp đồng
                bảo hiểm, hoặc để yêu cầu chúng tôi trả lời các câu hỏi,… chúng
                tôi có thể phải chia sẻ các thông tin đó cho các bên được ủy
                thác, nếu cần thiết, để phục vụ các yêu cầu của bạn. Chúng tôi
                cũng có thể sẽ gửi và lưu trữ các thông tin này tại các bên thứ
                ba làm dịch vụ xử lý, lưu trữ và/hoặc sao lưu dữ liệu cho công
                ty chúng tôi. Chúng tôi không bán các thông tin khách hàng cho
                các công ty cung cấp danh sách địa chỉ emails hay các công ty
                kinh doanh tiếp thị qua điện thoại.
              </p>
              <br />
              <h4>
                BẢO VỆ CÁC THÔNG TIN THU THẬP VÀ BẢO ĐẢM AN TOÀN THÔNG TIN
              </h4>
              <p>
                Các thông tin cá nhân bạn đã cung cấp sẽ được tiếp cận bởi một
                số nhân viên và đại lý của Gác Sấu Béo, những người cần biết các
                thông tin đó để phục vụ các yêu cầu của bạn. Gác Sấu Béo duy trì
                các biện pháp và quy trình bảo vệ thông tin tuân thủ các quy
                định của pháp luật nhằm bảo vệ các thông tin cá nhân của bạn.
                Gác Sấu Béo sẽ lưu giữ các thông tin thu thập được trong thời
                gian pháp luật yêu cầu hoặc cho phép.
              </p>
              <br />
              <h4>THÔNG BÁO VỀ CÁC THAY ĐỔI</h4>
              <p>
                Chính Sách Về Quyền Riêng Tư Trên Internet này có thể được sửa
                đổi vào từng thời điểm. Nếu Chính Sách Về Quyền Riêng Tư trên
                Internet thay đổi, chúng tôi sẽ cập nhật các thay đổi đó trên
                trang web này tại địa chỉ gacsaubeo.com. Bạn có thể vào trang
                web này thường xuyên để đọc bản cập nhật mới nhất của Chính Sách
                Về Quyền Riêng Tư Trên Internet.
              </p>
            </div>
            {/* <Sidebar /> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
