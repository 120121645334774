import React, { useState, useEffect } from "react";
import { API } from "../common/api-service";

export default function AdsIcon() {
  const [show, setShow] = useState(true);
  const initialState = {
    shopee_image: "",
    shopee_link: "",
    lazada_image: "",
    lazada_link: "",
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    API.getItemsFront(`read_from_file/`)
      .then((resp) => {
        var content = resp.file_content.split("---");
        if (content.length > 1) {
          setState({
            ...state,
            shopee_image: content[0],
            shopee_link: content[1],
            lazada_image: content[2],
            lazada_link: content[3],
          });
        } else {
          setState({
            ...state,
            shopee_image:
              "https://storage.googleapis.com/tinicorner/ads/shopee.png",
            shopee_link: "https://shorten.asia/kJy3QZZc",
            lazada_image:
              "https://storage.googleapis.com/tinicorner/ads/lazada.png",
            lazada_link: "https://shorten.asia/dRzSxeFK",
          });
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      {show ? (
        <div>
          <div className="ads-icon-2">
            <button
              className="btn-close-2"
              type="button"
              onClick={() => setShow(!show)}
            >
              &times;
            </button>
            <a href={state.shopee_link} target={"_blank"}>
              <img src={state.shopee_image} width="80" height="80" />
            </a>
          </div>
          <div className="ads-icon">
            {/* <button
              className="btn-close"
              type="button"
              onClick={() => setShow(!show)}
            >
              &times;
            </button> */}
            <a href={state.lazada_link} target={"_blank"}>
              <img src={state.lazada_image} width="80" height="80" />
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
