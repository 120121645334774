import React, { useState, useEffect } from "react";
import { API } from "../common/api-service";
import { dataCommonFunction } from "../common/data-common-function";
import { Link } from "react-router-dom";
import SingleCategory from "./SingleCategory";
import ChildrenCategory from "./ChildrenCategory";

export default function Sidebar() {
  const LASTEST_ARTICLE_QUANTITY = 4;
  const TAGS_QUANTITY = 20;
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const categoryStyle = {
    margin: `0 0 0 30px`,
  };

  useEffect(() => {
    var link = `get_lastest_articles_by_site_domain/${process.env.REACT_APP_DOMAIN}/${LASTEST_ARTICLE_QUANTITY}/`;
    API.getItemsFront(link)
      .then(async (resp) => {
        var datas = resp.articles;
        var parents_articles = resp.parents_articles;
        var articlesList = [];
        for (let i = 0; i < datas.length; i++) {
          const url = await dataCommonFunction
            .getThumpUrl(datas[i].id)
            .catch(console.error);
          var article = {
            article: {
              id: datas[i].id,
              title: datas[i].title,
              slug: datas[i].slug,
              created_at: datas[i].created_at,
              thumpUrl: url,
            },
            parents: {
              id: parents_articles[i].id,
              title: parents_articles[i].title,
              slug: parents_articles[i].slug,
            },
          };
          articlesList.push(article);
        }
        setArticles(articlesList);
      })
      .catch((error) => console.log(error));

    var link1 = `get_categories_by_site_domain/${process.env.REACT_APP_DOMAIN}/`;
    // var link1 = `get_categories_tree_by_site_domain/${process.env.REACT_APP_DOMAIN}/`;
    API.getItemsFront(link1)
      .then((resp2) => {
        setCategories(resp2.categories);
      })
      .catch((error) => console.log(error));

    var link2 = `get_tags_by_site_domain/${process.env.REACT_APP_DOMAIN}/${TAGS_QUANTITY}/`;
    API.getItemsFront(link2)
      .then((resp2) => {
        setTags(resp2.tags);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <aside className="sidebar">
      {/* <div className="side-wrap">
        <Agoda />
      </div>
      <div className="side-wrap">
        <BookingSearch />
      </div> */}
      <div className="side-wrap">
        <h2 className="sidebar-heading">Tôi là ai?</h2>
        <div
          className="author-img"
          style={{
            backgroundImage:
              "url(https://storage.googleapis.com/tinicorner_test/gac_dam_my_logo_1.jpg)",
          }}
        ></div>
        <p>
          Chào mừng các bạn đến với <strong>Gác truyện của Sấu Béo</strong>! Ở
          trang này, Sấu Béo sẽ gửi tới các bạn các tác phẩm truyện thuộc thể
          loại ngôn tình hiện đại, cổ đại, tiên hiệp, huyền huyễn, đam mỹ... Các
          bạn có đóng góp ý kiến hoặc có nhu cầu kênh đọc truyện nào thì có thể
          comment cho mình nhé!
        </p>
        <div>
          Các kênh Youtube của Sấu Béo:
          <ul>
            <li>
              {" "}
              <a
                href="https://www.youtube.com/channel/UCQTwVar7a63QBcNF0VKiPSw"
                target="_blank"
              >
                Gác truyện Ngôn tình cổ đại, hiện tại,...
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.youtube.com/channel/UCVHUKGiWqlrnEBMnHOAyN1w"
                target="_blank"
              >
                Gác truyện Đam mỹ
              </a>
            </li>
          </ul>
        </div>
        <div>
          Ủng hộ cho Sấu Béo qua các kênh sau:
          <ul>
            <li>
              {" "}
              <a href="https://me.momo.vn/saubeo" target="_blank">
                Momo
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.paypal.com/paypalme/yennguyenvn"
                target="_blank"
              >
                Paypal
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://www.buymeacoffee.com/samnguyen"
                target="_blank"
              >
                Buy me a coffee
              </a>
            </li>
            <li>
              Hoặc nếu các bạn cần mua sắm trên Shopee, Lazada, Tiki thì có thể
              mua qua các link sau để ủng hộ Sấu Béo nhé!
              <ul>
                <li>
                  <a href="https://shorten.asia/kJy3QZZc" target="_blank">
                    Shopee
                  </a>
                </li>
                <li>
                  <a href="https://shorten.asia/dRzSxeFK" target="_blank">
                    Lazada
                  </a>
                </li>
                <li>
                  <a href="https://shorten.asia/sGgY1Ujm" target="_blank">
                    Tiki
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <ul className="colorlib-social-icons">
            <li>
              <a
                href="https://www.youtube.com/channel/UCQTwVar7a63QBcNF0VKiPSw"
                target="_blank"
              >
                <i className="icon-youtube"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCVHUKGiWqlrnEBMnHOAyN1w"
                target="_blank"
              >
                <i className="icon-youtube"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/gactruyensaubeo"
                target="_blank"
              >
                <i className="icon-facebook"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="side-wrap">
        <h2 className="sidebar-heading">Recent Post</h2>
        {articles &&
          articles.map((data, index) =>
            data.parents.title != "" ? (
              <div className="f-entry" key={"sidebar" + index}>
                <Link
                  target="_blank"
                  to={{
                    pathname: `/articles/${data.parents.slug}/${data.article.slug}/`,
                    // state: { id: data.article.id },
                  }}
                  className="featured-img"
                  style={{
                    backgroundImage: data.article.thumpUrl,
                  }}
                ></Link>
                <div className="desc">
                  <span>
                    <i className="icon-calendar3"></i>{" "}
                    {new Intl.DateTimeFormat("en-US", {
                      month: "long",
                      day: "2-digit",
                      year: "numeric",
                    }).format(new Date(data.article.created_at))}
                  </span>
                  <h3>
                    <Link
                      target="_blank"
                      to={{
                        pathname: `/articles/${data.parents.slug}/${data.article.slug}/`,
                        // state: { id: data.id },
                      }}
                    >
                      {data.article.title}
                      {" - "}
                      {"("}
                      {data.parents.title}
                      {")"}
                    </Link>
                  </h3>
                </div>
              </div>
            ) : (
              <div className="f-entry" key={"sidebar" + index}>
                <Link
                  target="_blank"
                  to={{
                    pathname: `/articles/${data.article.slug}/`,
                    // state: { id: data.article.id },
                  }}
                  className="featured-img"
                  style={{
                    backgroundImage: data.article.thumpUrl,
                  }}
                ></Link>
                <div className="desc">
                  <span>
                    <i className="icon-calendar3"></i>{" "}
                    {new Intl.DateTimeFormat("en-US", {
                      month: "long",
                      day: "2-digit",
                      year: "numeric",
                    }).format(new Date(data.article.created_at))}
                  </span>
                  <h3>
                    <Link
                      target="_blank"
                      to={{
                        pathname: `/articles/${data.article.slug}/`,
                        // state: { id: data.id },
                      }}
                    >
                      {data.article.title}
                    </Link>
                  </h3>
                </div>
              </div>
            )
          )}
      </div>
      <div className="side-wrap">
        <h2 className="sidebar-heading">Categories</h2>
        <ul className="category">
          {categories &&
            categories.map(
              (rootCategory) =>
                !rootCategory.parent && (
                  <React.Fragment key={rootCategory.id}>
                    <div className="categories-box">
                      <SingleCategory
                        index={rootCategory.id}
                        cat={rootCategory}
                      />
                      <ChildrenCategory
                        categories={categories}
                        parentId={rootCategory.id}
                        style={categoryStyle}
                      />
                    </div>
                  </React.Fragment>
                )
            )}
        </ul>
      </div>
      {/* <div className="side-wrap">
        <h2 className="sidebar-heading">Video Post</h2>
        <div
          className="video colorlib-video"
          style={{
            backgroundImage: "url(images/blog-1.jpg)",
          }}
        >
          <a
            href="https://www.youtube.com/watch?v=urx08f8JN_Q"
            className="popup-vimeo"
          >
            <i className="icon-play"></i>
          </a>
          <div className="overlay"></div>
        </div>
      </div> */}
      <div className="side-wrap">
        <h2 className="sidebar-heading">Tags</h2>
        <p className="tags">
          {tags &&
            tags.map((tag, index) => (
              <span key={index}>
                <Link
                  to={{
                    pathname: `/tags/${tag.slug}/`,
                  }}
                >
                  <i className="icon-tag"></i> {tag.name}
                </Link>
              </span>
            ))}
        </p>
      </div>
      <div className="side-wrap">
        <p className="fb_fanpage">
          <div
            class="fb-page"
            data-href="https://www.facebook.com/gactruyensaubeo/"
            data-tabs="timeline"
            data-width=""
            data-height="330"
            data-small-header="false"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="true"
          >
            <blockquote
              cite="https://www.facebook.com/gactruyensaubeo/"
              className="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/gactruyensaubeo/">
                Gác truyện của Sấu Béo
              </a>
            </blockquote>
          </div>
        </p>
      </div>
      {/* <div className="side-wrap">
        <h2 className="sidebar-heading">Tags</h2>
      </div>
      <div className="side-wrap">
        <h2 className="sidebar-heading">Blockquote</h2>
        <blockquote>
          " Little Blind Text didn’t listen. She packed her seven versalia, put
          her initial into the belt and made herself on the way.
        </blockquote>
      </div> */}
    </aside>
  );
}
