import React, { useState, useEffect } from "react";
// import Sidebar from "../layout/Sidebar";
import { API } from "../common/api-service";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import { dataCommonFunction } from "../common/data-common-function";
import { Link } from "react-router-dom";
import Comments from "../comments/Comments";
import { FixedSizeList as List } from "react-window";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Sidebar from "../layout/Sidebar";

export default function ArticleDetail(props) {
  const shareUrl = window.location.href;
  const [article, setArticle] = useState({});
  // const [chapters, setChapters] = useState([]);
  const [articles, setArticles] = useState([]);

  const Chapter = ({ index, key, style }) => (
    <div key={key} style={style}>
      <Link
        target="_blank"
        to={{
          // pathname: `/articles/${article.slug}/chapter/${chapter.ordering + 1}/`,
          pathname: `/articles/${article.slug}/${articles[index].slug}/`,
        }}
      >
        {articles[index].title}
      </Link>
    </div>
  );

  useEffect(() => {
    var slug = props.match.params.slug;
    var link = `get_article_by_slug/${slug}/${process.env.REACT_APP_DOMAIN}/`;
    API.getItemsFront(link)
      .then(async (resp) => {
        var a = resp.article;
        const url = await dataCommonFunction
          .getThumpUrl(a.id)
          .catch(console.error);
        var cat_list = await API.getItemsFront(
          `get_cat_list_by_article_id/${a.id}/${process.env.REACT_APP_DOMAIN}/`
        )
          .then((resp1) => {
            return resp1.cat_list;
          })
          .catch((error) => console.log(error));
        setArticle({
          id: a.id,
          title: a.title,
          slug: a.slug,
          body: a.body,
          created_at: a.created_at,
          is_finished: a.is_finished,
          thumpUrl: url,
          cat_list: cat_list,
        });
        var link2 = `get_chapters_by_story_id/${a.id}/`;
        API.getItemsFront(link2)
          .then((resp1) => {
            // setChapters(resp1.chapters);
            setArticles(resp1.articles);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <aside id="colorlib-breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12 breadcrumbs text-center">
              {/* <h2>{article.title}</h2> */}
              <h2>Gác truyện của Sấu Béo</h2>
            </div>
          </div>
        </div>
      </aside>
      <div id="colorlib-container">
        <div className="container">
          <div className="row">
            <div className="content">
              {/* <div className="col-md-10 col-md-offset-1"> */}
              <article className="blog-entry">
                <div className="blog-wrap">
                  <span className="category text-center">
                    {article.cat_list &&
                      article.cat_list.map((cat, i) => (
                        <React.Fragment key={"cat-" + i}>
                          <Link
                            to={{
                              pathname: `/categories/${cat.slug}/`,
                              state: { id: cat.id },
                            }}
                          >
                            {cat.name}
                          </Link>
                          {i < article.cat_list.length - 1 ? " | " : ""}
                        </React.Fragment>
                      ))}
                  </span>
                  <h2 className="text-center">
                    <a href="blog.html">{article.title}</a>
                  </h2>
                  <div className="blog-image">
                    <div className="owl-carousel-custom blog-item">
                      <div className="item">
                        <a
                          href={void 0}
                          className="blog image-popup-link"
                          style={{
                            backgroundImage: article.thumpUrl,
                          }}
                        ></a>
                      </div>
                    </div>
                    <ul className="share">
                      <li className="text-vertical">
                        <i className="icon-share3"></i>
                      </li>
                      <li>
                        <FacebookShareButton
                          url={shareUrl}
                          quote={article.title}
                          hashtag="#gác_sấu_béo #truyện_audio #truyện_ngôn_tình #truyện_đam_mỹ #edit_truyện #đọc_truyện"
                        >
                          <i className="icon-facebook"></i>
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={shareUrl}
                          quote={article.title}
                          hashtag="#gác_sấu_béo #truyện_audio #truyện_ngôn_tình #truyện_đam_mỹ #edit_truyện #đọc_truyện"
                        >
                          <i className="icon-twitter"></i>
                        </TwitterShareButton>
                      </li>
                    </ul>
                  </div>
                  <span className="category text-center">
                    <a href="blog.html">
                      <i className="icon-calendar3"></i>{" "}
                      {article.created_at
                        ? new Intl.DateTimeFormat("en-US", {
                            month: "long",
                            day: "2-digit",
                            year: "numeric",
                          }).format(new Date(article.created_at))
                        : ""}
                    </a>{" "}
                    | {article.is_finished ? "Đã hoàn" : "Đang ra"}
                  </span>
                </div>
                <div className="desc">
                  <div className="first-letra">
                    {article.body ? parse(article.body) : ""}
                  </div>
                </div>
              </article>
              {articles.length > 0 ? (
                <article className="blog-entry chapters-box">
                  <List
                    width={950}
                    height={700}
                    itemCount={articles.length}
                    itemSize={35}
                  >
                    {Chapter}
                  </List>
                </article>
              ) : (
                ""
              )}
              <div>
                {/* <CommentForm /> */}
                <Comments
                  commentsUrl="http://localhost:3004/comments"
                  currentUserId="1"
                  item_id={article.id}
                />
              </div>
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
