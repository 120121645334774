import React, { useState } from "react";

const CommentForm = ({
  handleSubmit,
  submitLabel,
  parentId,
}) => {
  const MAXLENGTH = 2000;
  const initialState = {
    name: "",
    email: "",
    body: "",
    active: false,
    parent: parentId,
  };
  const [state, setState] = useState(initialState);
  const isTextareaDisabled =
    state.name.length === 0 ||
    state.email.length === 0 ||
    state.body.length === 0;
    
  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(state);
    setState(initialState);
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <div className="row form-group">
          <div className="col-md-12">
            <input
              id="name"
              name="name"
              className="form-control"
              type="text"
              placeholder="Your name"
              value={state.name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12">
            <input
              id="email"
              name="email"
              className="form-control"
              type="email"
              placeholder="Your email"
              value={state.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12">
            <textarea
              id="body"
              name="body"
              className="form-control"
              value={state.body}
              onChange={handleChange}
              placeholder="Say something"
              rows="5"
              maxLength={MAXLENGTH}
            />
          </div>
        </div>
        <div className="form-group">
          <input
            type="submit"
            value={submitLabel}
            className="btn btn-primary"
            disabled={isTextareaDisabled}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default CommentForm;
