import React, { useState, useEffect } from "react";
import CommentForm from "./CommentForm";
import SingleComment from "./SingleComment";
import ReplyComment from "./ReplyComment";
import { API } from "../common/api-service";

const Comments = ({ commentsUrl, currentUserId, item_id }) => {
  const [commentedMsg, setCommentedMsg] = useState("");
  const [backendComments, setBackendComments] = useState([]);

  const addComment = (data, parentId) => {
    data["article"] = item_id;
    API.createItemFront("guest_comments", data)
      .then((resp) => {
        setCommentedMsg(
          "Your comment has been added and will be previewed soon."
        );
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    API.getItemsFront(`get_comments_by_article_id/${item_id}/`)
      .then((resp) => {
        setBackendComments(resp.comments);
      })
      .catch((error) => console.log(error));
  }, [item_id]);

  return (
    <React.Fragment>
      <div className="comment-box">
        {backendComments.length > 1 ? (
          <h2 className="colorlib-heading-2">
            {backendComments.length} Comments
          </h2>
        ) : (
          <h2 className="colorlib-heading-2">
            {backendComments.length} Comment
          </h2>
        )}

        {backendComments &&
          backendComments.map(
            (rootComment) =>
              !rootComment.parent && (
                <React.Fragment key={rootComment.id}>
                  <div className="comment-post">
                    <SingleComment
                      comment={rootComment}
                      parentId={rootComment.id}
                      handleReplySubmit={addComment}
                      // replies={getReplies(rootComment.id)}
                      // activeComment={activeComment}
                      // setActiveComment={setActiveComment}
                      // addComment={addComment}
                      // deleteComment={deleteComment}
                      // updateComment={updateComment}
                      // currentUserId={currentUserId}
                    />
                    <ReplyComment
                      CommentLists={backendComments}
                      articleId={item_id}
                      parentCommentId={rootComment.id}
                      handleReplySubmit={addComment}
                    />
                  </div>
                </React.Fragment>
              )
          )}
      </div>
      <h4 className="colorlib-heading-4">{commentedMsg}</h4>
      <div className="comment-area">
        <h2 className="colorlib-heading-2">Leave a comment</h2>
        <CommentForm
          submitLabel="Post Comment"
          handleSubmit={addComment}
          item_id={item_id}
        />
      </div>
    </React.Fragment>
  );
};

export default Comments;
