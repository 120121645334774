import React from "react";
import { Link } from "react-router-dom";

export default function SingleCategory(props) {
  return (
    <React.Fragment>
      <li key={"cat" + props.index}>
        <Link
          target="_blank"
          to={{
            pathname: `/categories/${props.cat.slug}/`,
            state: { id: props.cat.id },
          }}
        >
          {props.cat.name}
        </Link>
      </li>
    </React.Fragment>
  );
}
