import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import NotFound from "./components/common/NotFound";
// import Articles from "./components/articles/Articles";
import Homepage from "./components/others/Homepage";
import ArticleDetail from "./components/articles/ArticleDetail";
import ChapterDetail from "./components/articles/ChapterDetail";
import Categories from "./components/articles/Categories";
import TagArticles from "./components/articles/TagArticles";
import AdsIcon from "./components/advertising/AdsIcon";
import React from "react";
import PrivacyPolicy from "./components/others/PrivacyPolicy";
import FinishedList from "./components/articles/FinishedList";
import UpdatingList from "./components/articles/UpdatingList";
// import Gallery from "./components/gallery/Gallery";
// import About from "./components/others/About";

function App() {
  return (
    <React.Fragment>
      <Router className="App">
        <Switch>
          <MainLayout>
            <Route path="/" component={Homepage} exact />
            <Route path="/privacy_policy/" component={PrivacyPolicy} exact />
            <Route path="/articles/" component={Homepage} exact />
            <Route path="/articles_finished/" component={FinishedList} exact />
            <Route path="/articles_updating/" component={UpdatingList} exact />
            <Route path="/articles/:slug/" component={ArticleDetail} exact />
            <Route
              path="/articles/:parents_slug/:children_slug/"
              component={ChapterDetail}
              exact
            />
            <Route path="/categories/:slug/" component={Categories} exact />
            <Route path="/tags/:slug/" component={TagArticles} exact />
            {/* <Route path="/gallery/" component={Gallery} exact />
          <Route path="/about/" component={About} exact />*/}
          </MainLayout>
          <Route component={NotFound} />
        </Switch>
      </Router>
      <AdsIcon />
    </React.Fragment>
  );
}

export default App;
