import React, { useState } from "react";

export default function Pagination({
  data,
  RenderComponent,
  title,
  pageLimit,
  dataLimit,
}) {
  const [pages] = useState(Math.round(data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let pageCount = Math.ceil(data.length / dataLimit);
    let start = Math.floor((currentPage - 1) / pageCount) * pageCount;
    return new Array(pageCount).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div>
      <div className="dataContainer">
        {getPaginatedData().map((d, idx) => (
          <RenderComponent key={idx} data={d} />
        ))}
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <ul className="pagination">
            <li className={`prev ${currentPage === 1 ? "disabled" : ""}`}>
              <a href={void(0)} onClick={goToPreviousPage}>
                &laquo;
              </a>
            </li>
            {getPaginationGroup().map((item, index) => (
              <li
                className={`paginationItem ${
                  currentPage === item ? "active" : null
                }`}
                key={index}
              >
                <a href={void(0)} onClick={changePage}>
                  {item}
                </a>
              </li>
            ))}
            <li className={`next ${currentPage === pages ? "disabled" : ""}`}>
              <a href={void(0)} onClick={goToNextPage}>
                &raquo;
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="pagination">
        <button
          onClick={goToPreviousPage}
          className={`prev ${currentPage === 1 ? "disabled" : ""}`}
        >
          prev
        </button>

        {getPaginationGroup().map((item, index) => (
          <button
            key={index}
            onClick={changePage}
            className={`paginationItem ${
              currentPage === item ? "active" : null
            }`}
          >
            <span>{item}</span>
          </button>
        ))}

        <button
          onClick={goToNextPage}
          className={`next ${currentPage === pages ? "disabled" : ""}`}
        >
          next
        </button>
      </div> */}
    </div>
  );
}
