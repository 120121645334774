import React, { useState, useEffect } from "react";
import { API } from "../common/api-service";
import { Link } from "react-router-dom";

export default function Footer() {
  const LASTEST_ARTICLE_QUANTITY = 4;
  const TAGS_QUANTITY = 20;
  const [articles, setArticles] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    var link = `get_lastest_articles_by_site_domain/${process.env.REACT_APP_DOMAIN}/${LASTEST_ARTICLE_QUANTITY}/`;
    API.getItemsFront(link)
      .then(async (resp) => {
        var datas = resp.articles;
        var parents_articles = resp.parents_articles;
        var articlesList = [];
        for (let i = 0; i < datas.length; i++) {
          var article = {
            article: {
              id: datas[i].id,
              title: datas[i].title,
              slug: datas[i].slug,
              created_at: datas[i].created_at,
            },
            parents: {
              id: parents_articles[i].id,
              title: parents_articles[i].title,
              slug: parents_articles[i].slug,
            },
          };
          articlesList.push(article);
        }
        setArticles(articlesList);
      })
      .catch((error) => console.log(error));

    var link2 = `get_tags_by_site_domain/${process.env.REACT_APP_DOMAIN}/${TAGS_QUANTITY}/`;
    API.getItemsFront(link2)
      .then((resp2) => {
        setTags(resp2.tags);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <footer id="colorlib-footer" role="contentinfo">
      <div className="container">
        <div className="row row-pb-md">
          <div className="col-md-4">
            <h2>Navigational</h2>
            <ul className="colorlib-footer-links">
              <li>
                <a href="/">
                  <i className="icon-check"></i> Home
                </a>
              </li>
              <li>
                <a href="/articles/">
                  <i className="icon-check"></i> Categories
                </a>
              </li>
              <li>
                <a href="/privacy_policy/">
                  <i className="icon-check"></i> Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h2>Recent Post</h2>
            <ul className="colorlib-footer-links">
              {articles &&
                articles.map((data, index) =>
                  data.parents.title != "" ? (
                    <li key={"footer" + index}>
                      <span>
                        {data.article.created_at
                          ? new Intl.DateTimeFormat("en-US", {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                            }).format(new Date(data.article.created_at))
                          : ""}
                      </span>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `/articles/${data.parents.slug}/${data.article.slug}/`,
                          // state: { id: data.article.id },
                        }}
                      >
                        {data.article.title}
                        {" - "}
                        {"("}
                        {data.parents.title}
                        {")"}
                      </Link>
                    </li>
                  ) : (
                    <li key={"footer" + index}>
                      <span>
                        {data.article.created_at
                          ? new Intl.DateTimeFormat("en-US", {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                            }).format(new Date(data.article.created_at))
                          : ""}
                      </span>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `/articles/${data.article.slug}/`,
                          // state: { id: data.article.id },
                        }}
                      >
                        {data.article.title}
                      </Link>
                    </li>
                  )
                )}
            </ul>
          </div>
          <div className="col-md-4 col-md-push-1">
            <h2>Tags</h2>
            <p className="tags">
              {tags &&
                tags.map((tag, index) => (
                  <span key={index}>
                    <Link
                      to={{
                        pathname: `/tags/${tag.slug}/`,
                      }}
                    >
                      <i className="icon-tag"></i> {tag.name}
                    </Link>
                  </span>
                ))}
            </p>
          </div>
          {/* <div className="col-md-4">
            <h2>Categories</h2>
            <ul className="colorlib-footer-links">
              {categories &&
                categories.map((cat, index) => (
                  <li key={"cat" + index}>
                    <a href="/">
                      {cat.name}
                    </a>
                  </li>
                ))}
            </ul>
          </div> */}
          {/* <div className="col-md-4 col-md-push-1">
            <h2>Tags</h2>
          </div> */}
        </div>
      </div>
    </footer>
  );
}
