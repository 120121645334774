import React from "react";
import Pagination from "../common/Pagination";
import Article from "./Article";

export default function ArticlesList(props) {
  return (
    <React.Fragment>
      {props.articles.length > 0 ? (
        <>
          <Pagination
            data={props.articles}
            RenderComponent={Article}
            title="Articles"
            pageLimit={4}
            dataLimit={10}
          />
        </>
      ) : (
        <h3>Không có bài viết nào</h3>
      )}
    </React.Fragment>
  );
}
