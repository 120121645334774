import React, { useState, useEffect } from "react";
import { API } from "../common/api-service";
// import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import SingleCategory from "./SingleCategory";
import ChildrenCategory from "./ChildrenCategory";

export default function Header() {
  const [categories, setCategories] = useState([]);
  const categoryStyle = {
    margin: `0 0 8px 10px`,
  };

  useEffect(() => {
    var link1 = `get_categories_by_site_domain/${process.env.REACT_APP_DOMAIN}/`;
    API.getItemsFront(link1)
      .then((resp2) => {
        setCategories(resp2.categories);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <nav className="colorlib-nav" role="navigation">
        <div className="top-menu">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-2">
                <div id="colorlib-logo">
                  <a href="/">Sau Beo.</a>
                </div>
              </div>
              <div className="col-xs-10 text-right menu-1">
                <ul>
                  <li>
                    {/* <NavLink exact to="/" activeClassName="active">
                      Trang chủ
                    </NavLink> */}
                    <Link
                      // target="_blank"
                      to={{
                        pathname: "/",
                        // state: { id: cat.id },
                      }}
                    >
                      Trang chủ
                    </Link>
                  </li>
                  <li>
                    <Link
                      // target="_blank"
                      to={{
                        pathname: "/articles_finished/",
                        // state: { id: cat.id },
                      }}
                    >
                      Đã hoàn
                    </Link>
                    {/* <NavLink
                      exact
                      to="/articles_finished/"
                      activeClassName="active"
                    >
                      Đã hoàn
                    </NavLink> */}
                  </li>
                  <li>
                    <Link
                      // target="_blank"
                      to={{
                        pathname: "/articles_updating/",
                        // state: { id: cat.id },
                      }}
                    >
                      Đang ra
                    </Link>
                    {/* <NavLink
                      exact
                      to="/articles_updating/"
                      activeClassName="active"
                    >
                      Đang ra
                    </NavLink> */}
                  </li>
                  <li className="has-dropdown">
                    <Link
                      // target="_blank"
                      to={{
                        pathname: "/articles/",
                        // state: { id: cat.id },
                      }}
                    >
                      Thể loại
                    </Link>
                    {/* <NavLink exact to="/articles/" activeClassName="active">
                      Thể loại
                    </NavLink> */}
                    <ul className="dropdown">
                      {/* {categories &&
                        categories.map((cat, index) => (
                          <li key={"cat" + index}>
                            <Link
                              target="_blank"
                              to={{
                                pathname: `/categories/${cat.slug}/`,
                                state: { id: cat.id },
                              }}
                            >
                              {cat.name}
                            </Link>
                          </li>
                        ))} */}
                      {categories &&
                        categories.map(
                          (rootCategory) =>
                            !rootCategory.parent && (
                              <React.Fragment key={rootCategory.id}>
                                <div className="categories-box">
                                  <SingleCategory
                                    index={rootCategory.id}
                                    cat={rootCategory}
                                  />
                                  <ChildrenCategory
                                    categories={categories}
                                    parentId={rootCategory.id}
                                    style={categoryStyle}
                                  />
                                </div>
                              </React.Fragment>
                            )
                        )}
                    </ul>
                  </li>
                  {/* <li>
                    <NavLink exact to="/articles/" activeClassName="active">
                      Danh mục
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                      exact
                      to="/about/"
                      activeClassName="active"
                    >
                      About Me
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
