import react from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function MainLayout(props) {
  return (
    <div>
      <Header/>
      {props.children}
      <Footer/>
    </div>
  );
}
