import React, { useState } from "react";
import CommentForm from "./CommentForm";
import moment from "moment";

const SingleComment = ({ comment, handleReplySubmit, parentId = null }) => {
  const [isReplying, setIsReplying] = useState(false);
  const onOpenReplyBox = () => {
    setIsReplying(!isReplying);
  };

  return (
    <React.Fragment>
      <div
        className="user"
        style={{
          backgroundImage:
            "url(https://storage.googleapis.com/tinicorner_test/comment.jpg)",
        }}
      ></div>
      <div className="desc">
        <h3>
          {comment.name}{" "}
          <span>
            Commented on{" "}
            {moment.utc(comment.created).local().startOf("seconds").fromNow()}
          </span>
        </h3>
        <p>{comment.body}</p>
        <a href={void 0} onClick={onOpenReplyBox}>
          Reply
        </a>
        {isReplying ? (
          <CommentForm
            submitLabel="Reply"
            parentId={parentId}
            handleSubmit={handleReplySubmit}
          />
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default SingleComment;
