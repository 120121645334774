import React, { useState, useEffect } from "react";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { API } from "../common/api-service";
import { FacebookShareButton, TwitterShareButton } from "react-share";

export default function Article(props) {
  const [backendComments, setBackendComments] = useState([]);
  const shareUrl = window.location.href + "articles/" + props.data.slug;

  useEffect(() => {
    API.getItemsFront(`get_comments_by_article_id/${props.data.id}/`)
      .then((resp) => {
        setBackendComments(resp.comments);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <article className="blog-entry">
        <div className="blog-wrap">
          <span className="category text-center">
            {props.data.cat_list &&
              props.data.cat_list.map((cat, i) => (
                <React.Fragment key={"cat-" + props.data.id + "-" + i}>
                  <Link
                    to={{
                      pathname: `/categories/${cat.slug}/`,
                      state: { id: cat.id },
                    }}
                  >
                    {cat.name}
                  </Link>
                  {i < props.data.cat_list.length - 1 ? " | " : ""}
                </React.Fragment>
              ))}
          </span>
          <h2 className="text-center">
            <Link
              target="_blank"
              to={{
                pathname: `/articles/${props.data.slug}/`,
                // state: { id: props.data.id },
              }}
            >
              {props.data.title}
            </Link>
          </h2>
          <div className="blog-image">
            <Link
              target="_blank"
              to={{
                pathname: `/articles/${props.data.slug}/`,
                // state: { id: props.data.id },
              }}
              className="blog-img text-center"
              style={{
                backgroundImage: props.data.thumpUrl,
              }}
            >
              <span>
                <i className="icon-link"></i>
              </span>
            </Link>
            <ul className="share">
              <li className="text-vertical">
                <i className="icon-share3"></i>
              </li>
              <li>
                <FacebookShareButton
                  url={shareUrl}
                  quote={props.data.title}
                  hashtag="#gác_sấu_béo #truyện_audio #truyện_ngôn_tình #truyện_đam_mỹ #edit_truyện #đọc_truyện"
                >
                  <i className="icon-facebook"></i>
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton
                  url={shareUrl}
                  quote={props.data.title}
                  hashtag="#gác_sấu_béo #truyện_audio #truyện_ngôn_tình #truyện_đam_mỹ #edit_truyện #đọc_truyện"
                >
                  <i className="icon-twitter"></i>
                </TwitterShareButton>
              </li>
            </ul>
          </div>
          <span className="category text-center">
            <a href="blog.html">
              <i className="icon-calendar3"></i>{" "}
              {new Intl.DateTimeFormat("en-US", {
                month: "long",
                day: "2-digit",
                year: "numeric",
              }).format(new Date(props.data.created_at))}
            </a>{" "}
            | {props.data.is_finished ? "Đã hoàn" : "Đang ra"} |{" "}
            {backendComments.length > 1 ? (
              <a href={void 0}>
                <i className="icon-bubble3"></i> {backendComments.length}{" "}
                Comments
              </a>
            ) : (
              <a href={void 0}>
                <i className="icon-bubble3"></i> {backendComments.length}{" "}
                Comment
              </a>
            )}
          </span>
        </div>
        <div className="desc">
          <div className="first-letra">
            {props.data.body ? parse(props.data.body) : ""}
          </div>
        </div>
        <p className="text-center">
          <Link
            target="_blank"
            to={{
              pathname: `/articles/${props.data.slug}/`,
              //   state: { id: props.data.id },
            }}
            className="btn btn-primary btn-custom"
          >
            Continue Reading
          </Link>
        </p>
      </article>
    </React.Fragment>
  );
}
