import React, { useState, useEffect } from "react";
// import Sidebar from "../layout/Sidebar";
import { API } from "../common/api-service";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import { dataCommonFunction } from "../common/data-common-function";
import { Link } from "react-router-dom";
// import YoutubePlayer from "../common/YoutubePlayer";
import YouTube from "react-youtube";
import Comments from "../comments/Comments";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Sidebar from "../layout/Sidebar";
import CryptoJS from "crypto-js";

export default function ChapterDetail(props) {
  const shareUrl = window.location.href;
  const [article, setArticle] = useState({});
  // const [parentsTitle, setParentsTitle] = useState("");
  const [chapters, setChapters] = useState([]);
  const [ordering, setOrdering] = useState(0);
  const [decryptedBody, setDecryptedBody] = useState("");

  const renderChaptersList = () => (
    <span className="category text-center" id="chaptersList">
      <Link
        to={{
          pathname: `/articles/${props.match.params.parents_slug}/`,
        }}
      >
        Văn án
      </Link>{" "}
      |{" "}
      {ordering == 0 ? (
        ""
      ) : (
        <React.Fragment>
          <Link
            to={{
              pathname: `/articles/${props.match.params.parents_slug}/${
                chapters[ordering - 1]?.slug
              }/`,
            }}
          >
            Chương trước
          </Link>{" "}
          |{" "}
        </React.Fragment>
      )}
      {/* <a href="blog.html">Mục lục</a> */}
      <div className="dropdown">
        <a
          href={void 0}
          className="dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-labelledby
          d="false"
        >
          Mục lục
        </a>
        {/* <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expande
          d="false"
        >
          {" "}
          Mục lục{" "}
        </button> */}
        <div
          className="dropdown-menu pre-scrollable"
          aria-labelledby="dropdownMenuButton"
        >
          {chapters &&
            chapters.map((chapter, i) => (
              <Link
                key={i}
                className="dropdown-item"
                to={{
                  pathname: `/articles/${props.match.params.parents_slug}/${chapter.slug}/`,
                }}
              >
                {chapter.title}
              </Link>
            ))}
        </div>
      </div>
      {ordering == chapters.length - 1 ? (
        ""
      ) : (
        <React.Fragment>
          {" "}
          |{" "}
          <Link
            to={{
              pathname: `/articles/${props.match.params.parents_slug}/${
                chapters[ordering + 1]?.slug
              }/`,
            }}
          >
            Chương sau
          </Link>
        </React.Fragment>
      )}
    </span>
  );

  useEffect(() => {
    var parents_slug = props.match.params.parents_slug;
    var children_slug = props.match.params.children_slug;
    var link = `get_chapter_by_children_slug/${parents_slug}/${children_slug}/${process.env.REACT_APP_DOMAIN}/`;
    API.getItemsFront(link)
      .then(async (resp) => {
        var a = resp.article;
        const url = await dataCommonFunction
          .getThumpUrl(a.id)
          .catch(console.error);

        const encryptedData = a.temp;
        const base64Key = a.milk;
        const decryptData = () => {
          const key = CryptoJS.enc.Base64.parse(base64Key);
          const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
          const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
          const ciphertext = CryptoJS.lib.WordArray.create(
            dataBytes.words.slice(4)
          );
          const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
            iv,
          });
          const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
          setDecryptedBody(decryptedText);
        };
        decryptData();

        setArticle({
          id: a.id,
          title: a.title,
          slug: a.slug,
          video_url: a.video_url,
          created_at: a.created_at,
          thumpUrl: url,
        });
        // setParentsTitle(resp.parents.title);
        setChapters(resp.chapters);
        setOrdering(resp.chapters.findIndex((x) => x.id === a.id));
      })
      .catch((error) => console.log(error));
  }, [props.match.params.children_slug]);

  return (
    <React.Fragment>
      <aside id="colorlib-breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12 breadcrumbs text-center">
              {/* <h2>{parentsTitle}</h2> */}
              <h2>Gác truyện của Sấu Béo</h2>
            </div>
          </div>
        </div>
      </aside>
      <div id="colorlib-container">
        <div className="container">
          <div className="row">
            <div className="content">
              {/* <div className="col-md-10 col-md-offset-1"> */}
              <article className="blog-entry">
                <div className="blog-wrap">
                  {renderChaptersList()}
                  <h2 className="text-center">
                    <a href="blog.html">{article.title}</a>
                  </h2>
                  <div className="blog-image">
                    <div className="owl-carousel-custom blog-item">
                      <div className="item">
                        <a
                          href={void 0}
                          className="blog image-popup-link"
                          style={{
                            backgroundImage: article.thumpUrl,
                          }}
                        ></a>
                      </div>
                    </div>
                    <ul className="share">
                      <li className="text-vertical">
                        <i className="icon-share3"></i>
                      </li>
                      <li>
                        <FacebookShareButton
                          url={shareUrl}
                          quote={article.title}
                          hashtag="#gác_sấu_béo #truyện_audio #truyện_ngôn_tình #truyện_đam_mỹ #edit_truyện #đọc_truyện"
                        >
                          <i className="icon-facebook"></i>
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={shareUrl}
                          quote={article.title}
                          hashtag="#gác_sấu_béo #truyện_audio #truyện_ngôn_tình #truyện_đam_mỹ #edit_truyện #đọc_truyện"
                        >
                          <i className="icon-twitter"></i>
                        </TwitterShareButton>
                      </li>
                    </ul>
                  </div>
                  <span className="category text-center">
                    <a href="blog.html">
                      <i className="icon-calendar3"></i>{" "}
                      {article.created_at
                        ? new Intl.DateTimeFormat("en-US", {
                            month: "long",
                            day: "2-digit",
                            year: "numeric",
                          }).format(new Date(article.created_at))
                        : ""}
                    </a>{" "}
                  </span>
                </div>
                <div className="desc">
                  <div className="first-letra">
                    {decryptedBody ? parse(decryptedBody) : ""}
                  </div>
                </div>
                <div style={{ margin: "50px 0 0 0", padding: "50px 0 0 0" }}>
                  {article.video_url && article.video_url != "" ? (
                    <div className="desc">
                      {/* <YoutubePlayer video_url={article.video_url} title={article.title}/> */}
                      <YouTube videoId={article.video_url} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </article>
              <article className="blog-entry">{renderChaptersList()}</article>
              <div>
                {/* <CommentForm /> */}
                <Comments
                  commentsUrl="http://localhost:3004/comments"
                  currentUserId="1"
                  item_id={article.id}
                />
              </div>
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
